<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-icon-selector
        [label]="moduleFieldString + '.icon.label' | translate: 'Icon'"
        [placeholder]="moduleFieldString + '.icon.placeholder' | translate: 'Select Icon'"
        [control]="formGroup?.controls?.icon"
        [viewMode]="fieldViewMode"
      >
      </app-icon-selector>
    </div>
    <div class="col-12">
      <app-color-input
        [label]="moduleFieldString + '.color.label' | translate: 'Color'"
        name="Color"
        [control]="formGroup?.controls?.color"
        [viewMode]="fieldViewMode"
      >
      </app-color-input>
    </div>
    <div class="col-12 md:col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.dashboards.label' | translate: 'Dashboards'"
        [placeholder]="moduleFieldString + '.dashboards.placeholder' | translate: 'Search Dashboards'"
        [control]="formGroup?.controls?.dashboards"
        [viewMode]="fieldViewMode"
        [multi]="true"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['DASHBOARD']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12">
      <app-text-editor
        [height]="'150px'"
        [name]="'description'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Description'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && showSaveAndClose) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create' && showSaveAndClose) {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create' && showSaveAndClose) {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
